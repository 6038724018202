import { Card, Button, TextField, Typography, Divider, Select, MenuItem, Input } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Container } from "@mui/system";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { createPerformanceAsync, createPerformanceDataAsync, createTestAsync, updateTestAsync } from "./test.slice";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import testService from "../../services/test.service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { getPlayersAsync } from "../player/player.slice";
import { Test } from "TestModels";
import playertests from "../../services/schemas/playerstatistics";
import {
    DataGrid, 
    GridColDef, 
    GridRowModel, 
    useGridApiRef 
} from '@mui/x-data-grid';
import { diff } from "deep-object-diff";
import 'dayjs/locale/fi';
import updateLocale from 'dayjs/plugin/updateLocale';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';


const TestForm = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const editMode = ( params.id !== undefined );
    const [formValue, setFormValue] = useState<Test>({
        date: "",
        performances: []
    });
    const [footerData, setFooterData] = useState<object[]>([]);
    const {date, performances} = formValue;
    const [playerId, setPlayerId] = useState('');
    const [rows, setRows] = useState<object[]>([]);
    const isPlayerSelected = (playerId:string) => {
        return performances.findIndex(p => p.player?.id === playerId) !== -1
    }

    dayjs.extend(updateLocale);
    dayjs.updateLocale('fi', {
        // Sunday = 0, Monday = 1.
        weekStart: 1,
    });

    useEffect(() => {
        dispatch( getPlayersAsync() );
    }, []);
    const { players } = useAppSelector( (state) => state.player );

    const loadTest = async () => {
        try {
            const response = await testService.getTest(params.id as string);
            setFormValue(response);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const timerID = setInterval(() => {
            loadTest();
            return true;
        }, 5000);
        return () => { 
            clearInterval(timerID);
        };
    }, []);

    useEffect(() => {
        if (params.id) {
            loadTest();
        }
    }, [params.id]);

    const updateDataValue = (pId:string | undefined, pKey:string, pValue:string) => {
        const updatedPerformances = performances.map(performance => {
          if (performance.id === pId) {
            const updatedPerformance = { ...performance };
            let keyExists = false;
            const updatedData = updatedPerformance.data.map(data => {
                if (data.key === pKey) {
                    keyExists = true;
                    return { ...data, value: pValue };
                }
                return data;
            });
            if( !keyExists && pId ){
                updatedData.push({
                    performanceId: pId,
                    key: pKey,
                    value: pValue
                })
            }
            updatedPerformance.data = updatedData;
            return updatedPerformance;
          }
          return performance;
        });    
        setFormValue({
            ...formValue,
            performances: updatedPerformances
        })
      };

    const handlePlayerSelect = (e:any) => {
        setPlayerId(e.target.value);
    }

    const handleDateChange = (value:any) => {
        setFormValue({
            ...formValue,
            'date': value
        })
    }

    const addRowHandler = () => {
        const testId:string = params.id as string;
        if( playerId && testId ){
            dispatch(
                createPerformanceAsync({
                    playerId: playerId,
                    testId: testId,
                    data: []
                })
            )
            .unwrap()
            .then((data) => {
              navigate('/test/edit/'+params.id);
              window.location.reload();
            })
            .catch(() => {});
        }
        setPlayerId('');
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if( params.id ){
            dispatch(
                updateTestAsync({
                    id: params.id,
                    date: date,
                    performances: performances
                })
            )
            .unwrap()
            .then(() => {
              navigate('/test');
              window.location.reload();
            })
            .catch(() => {});
            
        } else {
            dispatch(
                createTestAsync({
                    date: date,
                    performances: performances
                })
            )
            .unwrap()
            .then((data) => {
              navigate('/test/edit/'+data.id);
              window.location.reload();
            })
            .catch(() => {});
        }
    }

    /***
     * 
     */
    const apiRef = useGridApiRef();
    const sumApiRef = useGridApiRef();

    useEffect(() => {
        if( params.id ){
            apiRef.current.subscribeEvent('scrollPositionChange', (params) => {
                sumApiRef.current.scroll({left:params.left})
            });      
        } 
    }, [apiRef]);   

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: "Nimi",
            editable: false,
            type: 'string',
            width: 200
        }
    ];
    playertests.performances?.forEach(g => {
        columns.push({
            field: g.id,
            headerName: g.title,
            type: 'number',
            editable: true
        });
    });
    
    useEffect(() => {

        let rData:object[] = [];
        let fDataCalc:object[] = [];
        performances?.forEach((d) => {
            let pData:any = [];
            d.data?.forEach((pd) => {
                pData[pd.key as keyof object] = pd.value;
            })
            rData.push({
                id: d.id,
                name: d.player?.firstname + " " + d.player?.lastname,
                ...pData
            });
            for(const fd in pData){
                if( pData[fd as keyof object] != undefined ){
                    if( fDataCalc[fd as keyof object] ){
                        const oldfd:any = fDataCalc[fd as keyof object];
                        fDataCalc[fd as keyof object] = {
                            sum: Number(oldfd.sum) + Number(pData[fd as keyof object]),
                            count: oldfd.count+1
                        }
                    } else {
                        fDataCalc[fd as keyof object] = {
                            sum: Number(pData[fd as keyof object]),
                            count: 1
                        }
                    }
                }
            }
        });
        setRows(rData);

        let fDataUpdated:any = {
            id: '',
            name: 'KESKIARVO',
        }
        for(const key in fDataCalc){
            const fdc:any = fDataCalc[key];
            fDataUpdated[key as keyof object] = fdc.sum / fdc.count;
        };
        setFooterData([fDataUpdated]);

    }, [performances]);

    const processRowUpdate = (updatedRow: GridRowModel, originalRow: GridRowModel) => {
        // DATA
        const diffValue:any = diff(originalRow, updatedRow);
        const performanceId = originalRow.id;
        const performanceKey = Object.keys(diffValue)[0];
        let performanceValue = diffValue[performanceKey as keyof object];
        // UPDATE
        if( performanceId && performanceKey && performanceValue !== undefined ){
            performanceValue = performanceValue === null ? null : String(performanceValue);
            updateDataValue(performanceId, performanceKey, performanceValue);
            dispatch(
                createPerformanceDataAsync({
                    performanceId: performanceId,
                    key: performanceKey,
                    value: performanceValue
                })
            )
            .unwrap()
            .then(() => {
                //loadTest();
            })
            .catch(() => {});
        }
        // return
        return updatedRow;
    };
    /**
     * 
     */

    return (
        <Container maxWidth="xl">

            <Typography variant="h4" sx={{my:5}}>
                { editMode ? 'Muokkaa testiä' : 'Luo uusi testi' }
            </Typography>

            <Card variant="outlined" sx={{p:3, mb:5, borderRadius:3, boxShadow: 3}}>
                <Box component="form" onSubmit={handleSubmit}>

                    <Grid 
                        container 
                        spacing={2} 
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid xs={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi">
                                <DatePicker
                                    name="date"
                                    format="DD.MM.YYYY"
                                    value={dayjs(date)}
                                    defaultValue={dayjs()}
                                    slotProps={{ 
                                        textField: { 
                                            fullWidth: true,
                                            size: "small"
                                        }
                                    }}
                                    onChange={handleDateChange} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid xs={4}></Grid>
                        <Grid xs={4} textAlign="right">
                            <Button 
                                variant="contained" 
                                color="success"
                                type="submit"
                                startIcon={<SaveIcon />}>
                                { editMode ? 'Tallenna päivämäärä' : 'Luo uusi' }
                            </Button>
                            <Button 
                                href="/test"
                                style={{marginLeft:10}}
                                variant="contained" 
                                color="info"
                                startIcon={<ArrowBackIcon />}>
                                { editMode ? 'Listaan' : 'Peruuta' }
                            </Button>
                        </Grid>
                    </Grid>

                { editMode &&

                    <>

                    <DataGrid
                        sx={{ mt:2, mb:2 }}
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },
                            },
                        }}
                        processRowUpdate={processRowUpdate}
                        pageSizeOptions={[50]}
                        disableRowSelectionOnClick
                        hideFooter
                    />

                    <DataGrid
                        sx={{ 
                            mt:2, 
                            mb:2, 
                            backgroundColor:'#F0F0F0', 
                            "& ::-webkit-scrollbar": {
                                display: "none"
                            }
                        }}
                        columnHeaderHeight={1}
                        rows={footerData}
                        columns={columns}
                        apiRef={sumApiRef}
                        slots={{
                            toolbar: () => null,
                        }}
                        hideFooter
                        disableRowSelectionOnClick
                    />       

                    <Grid container spacing={2}>
                        <Grid xs={4}>
                            <Select
                                size="small"
                                variant="outlined"
                                name="playerId"
                                value={playerId}
                                fullWidth={true}
                                onChange={handlePlayerSelect}
                                >
                                {players.map((player:any) => {
                                    return(
                                        <MenuItem 
                                            disabled={isPlayerSelected(player.id)}
                                            key={player.id}
                                            value={player.id}>
                                            {player.firstname} {player.lastname}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Grid xs={8}>
                            <Button 
                                variant="contained" 
                                color="info"
                                onClick={() => addRowHandler()}
                                startIcon={<AddIcon />}>
                                Lisää
                            </Button>
                        </Grid>
                     </Grid>

                     </>
                }

                </Box>
            </Card>

        </Container>
    )

}

export default TestForm;