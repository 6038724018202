import { Card, Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Container } from "@mui/system";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import playerService from "../../services/player.service";
import testService from "../../services/test.service";
import playertests from "../../services/schemas/playerstatistics";
import { Test } from "TestModels";
import Moment from "react-moment";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GPS } from "GPSModels";
import gpsService from "../../services/gps.service";

const PlayerForm = () => {

    const params = useParams();

    const [player, setPlayer] = useState({
        id: "",
        firstname: "",
        lastname: "",
        teamId: "",
        number: "",
        position: "",
        targets: "",
        strengths: ""
    });
    const { id, firstname, lastname, teamId, number, position, targets, strengths } = player;

    const [tests, setTests] = useState<Test[]>([]);
    const [gpss, setGpss] = useState<GPS[]>();

    const [calculatedTestData, setCalculatedTestData] = useState<any>({});
    const { playerTestData, playerTestAverages, teamTestAverages, teamTestAveragesTotal } = calculatedTestData;

    const [calculatedGPSData, setCalculatedGPSData] = useState<any>({});
    const { playerGPSData, playerGPSAverages, teamGPSAverages, teamGPSAveragesTotal } = calculatedGPSData;

    /**
     * TEST
     */
    useEffect(() => {

        let playerTestData:any = {};
        let playerTestAverages:any = {};
        let teamTestAverages:any = {}
        let teamTestAveragesTotal:any = {};

        tests?.forEach( (test) => {
            let testID = test?.id;
            test.performances?.forEach( (performance) => {
                performance.data?.forEach( (d) => {
                    // is current player
                    if( player.id == performance.player?.id) {
                        // set data, creata object if not exists
                        if(!playerTestData[testID as keyof object]) 
                            playerTestData[testID as keyof object] = {};

                        playerTestData[testID as keyof object][d.key as keyof object] = d.value; 
                        // calculate averages
                        if(!playerTestAverages[d.key as keyof object]) 
                            playerTestAverages[d.key as keyof object] = {'sum': 0, 'count': 0};

                        playerTestAverages[d.key as keyof object]['sum'] += Number(d.value);
                        playerTestAverages[d.key as keyof object]['count'] += 1;
                    }
                    // all 
                    // calculate averages by date
                    if(!teamTestAverages[testID as keyof object]) 
                        teamTestAverages[testID as keyof object] = {};

                    if(!teamTestAverages[testID as keyof object][d.key as keyof object]) 
                        teamTestAverages[testID as keyof object][d.key as keyof object] = {'sum': 0, 'count': 0}

                    teamTestAverages[testID as keyof object][d.key as keyof object]['sum'] += Number(d.value);
                    teamTestAverages[testID as keyof object][d.key as keyof object]['count'] += 1;

                    // calculate averages total
                    if(!teamTestAveragesTotal[d.key as keyof object]) 
                        teamTestAveragesTotal[d.key as keyof object] = {'sum': 0, 'count': 0};
                    
                    teamTestAveragesTotal[d.key as keyof object]['sum'] += Number(d.value);
                    teamTestAveragesTotal[d.key as keyof object]['count'] += 1;
                })
            })
        })

        const calculatedData: any = {
            'playerTestData': playerTestData,
            'playerTestAverages': playerTestAverages,
            'teamTestAverages': teamTestAverages,
            'teamTestAveragesTotal': teamTestAveragesTotal
        }
        setCalculatedTestData(calculatedData);

    }, [tests])

    const getPlayerTestValue = (testID:string | undefined, performanceKey:string) => {
        if( !playerTestData ) return "";
        const test = playerTestData[testID as keyof object];
        if( test == undefined ) return "";
        const value = test[performanceKey as keyof object];
        if( value == undefined ) return "";
        return value;
    }

    const getPlayerTestAverage = (performanceKey:string) => {
        if( !playerTestAverages ) return "";
        const average = playerTestAverages[performanceKey as keyof object];
        if( average == undefined ) return "";
        return Math.round( 100 * ( average.sum / average.count)) / 100;
    }

    const getTeamTestAverage = (testID:string | undefined, performanceKey:string) => {
        if( !teamTestAverages ) return "";
        const average = teamTestAverages[testID as keyof object];
        if( average == undefined ) return "";
        const value = average[performanceKey as keyof object];
        if( value == undefined ) return "";
        return Math.round( 100 * ( value.sum / value.count )) / 100;
    }

    const getTeamTestTotalAverage = (performanceKey:string) => {
        if( !teamTestAveragesTotal ) return "";
        const average = teamTestAveragesTotal[performanceKey as keyof object];
        if( average == undefined ) return "";
        return Math.round( 100 * ( average.sum / average.count )) / 100;
    }

    const getPlayerTestAveragePercent = ( key:string ) => {
        const playerAvg = getPlayerTestAverage(key);
        const teamAvg = getTeamTestTotalAverage(key);
        const avgPercent = Math.round( Number(playerAvg) / Number(teamAvg) * 100 );
        return ( avgPercent == 0 || isNaN(avgPercent) ) ? "" : avgPercent;
    }

    /**
     * GPS
     */
    const getPlayerGPSValue = (gpsId:string | undefined, key:string) => {
        if( !playerGPSData ) return "";
        const gps = playerGPSData[gpsId as keyof object];
        if( gps == undefined ) return "";
        const value = gps[key as keyof object];
        if( value == undefined ) return "";
        return value;
    }

    const getPlayerGPSAverage = (key:string) => {
        if( !playerGPSAverages ) return "";
        const average = playerGPSAverages[key as keyof object];
        if( average == undefined ) return "";
        return Math.round( 100 * ( average.sum / average.count)) / 100;
    }

    const getTeamGPSAverage = (gpsId:string | undefined, key:string) => {
        if( !teamGPSAverages ) return "";
        const average = teamGPSAverages[gpsId as keyof object];
        if( average == undefined ) return "";
        const value = average[key as keyof object];
        if( value == undefined ) return "";
        return Math.round( 100 * ( value.sum / value.count )) / 100;
    }

    const getTeamGPSTotalAverage = (key:string) => {
        if( !teamGPSAveragesTotal ) return "";
        const average = teamGPSAveragesTotal[key as keyof object];
        if( average == undefined ) return "";
        return Math.round( 100 * ( average.sum / average.count )) / 100;
    }

    const getPlayerGPSAveragePercent = ( key:string ) => {
        const playerAvg = getPlayerGPSAverage(key);
        const teamAvg = getTeamGPSTotalAverage(key);
        const avgPercent = Math.round( Number(playerAvg) / Number(teamAvg) * 100 );
        return ( avgPercent == 0 || isNaN(avgPercent) ) ? "" : avgPercent;
    }

    useEffect(() => {

        let playerGPSData:any = {};
        let playerGPSAverages:any = {};
        let teamGPSAverages:any = {}
        let teamGPSAveragesTotal:any = {};

        gpss?.forEach( (gps) => {
            let gpsID = gps?.id;
            gps.data.forEach( (d) => {
                for (var key in d.data) {
                    // is current player
                    if( player.id == d.player?.id) {
                        // set data, create object if not exists
                        if(!playerGPSData[gpsID as keyof object])
                            playerGPSData[gpsID as keyof object] = {};

                        playerGPSData[gpsID as keyof object][key as keyof object] = d.data[key as keyof object];
                        // calculate averages
                        if(!playerGPSAverages[key as keyof object]) 
                            playerGPSAverages[key as keyof object] = {'sum': 0, 'count': 0};

                        playerGPSAverages[key as keyof object]['sum'] += Number(d.data[key as keyof object]);
                        playerGPSAverages[key as keyof object]['count'] += 1;

                    }
                    // all
                    // calculate averages by date
                    if(!teamGPSAverages[gpsID as keyof object]) 
                        teamGPSAverages[gpsID as keyof object] = {};

                    if(!teamGPSAverages[gpsID as keyof object][key as keyof object]) 
                        teamGPSAverages[gpsID as keyof object][key as keyof object] = {'sum': 0, 'count': 0}

                    teamGPSAverages[gpsID as keyof object][key as keyof object]['sum'] += Number(d.data[key as keyof object]);
                    teamGPSAverages[gpsID as keyof object][key as keyof object]['count'] += 1;
                    // calculate averages total
                    if(!teamGPSAveragesTotal[key as keyof object]) 
                        teamGPSAveragesTotal[key as keyof object] = {'sum': 0, 'count': 0};
                    
                    teamGPSAveragesTotal[key as keyof object]['sum'] += Number(d.data[key as keyof object]);
                    teamGPSAveragesTotal[key as keyof object]['count'] += 1;
                }
            });
        })

        const calculatedData: any = {
            'playerGPSData': playerGPSData,
            'playerGPSAverages': playerGPSAverages,
            'teamGPSAverages': teamGPSAverages,
            'teamGPSAveragesTotal': teamGPSAveragesTotal
        }
        setCalculatedGPSData(calculatedData);

    }, [gpss])


    /**
     * SERVER DATA
     */
    useEffect(() => {
        if (params.id) {
          // player
          const loadPlayer = async () => {
            try {
              const response = await playerService.getPlayer(params.id as string);
              setPlayer(response);
            } catch (error) {
              console.error(error);
            }
          };
          loadPlayer();
          // gps 
          const loadGpss = async () => {
            try {
              const response = await gpsService.getGpssByPlayer(params.id as string);
              setGpss(response);
            } catch (error) {
              console.error(error);
            }
          };
          loadGpss();
          // tests
          const loadTests = async () => {
            try {
              const response = await testService.getTestsByPlayer(params.id as string);
              setTests(response);
            } catch (error) {
              console.error(error);
            }
          };
          loadTests();
        }
    }, [params.id]);


    return (
        <Container maxWidth="xl">

            <Grid container sx={{my:5}}>
                <Grid xs={8}>
                    <Typography variant="h4">
                    {firstname} {lastname}
                    </Typography>
                </Grid>
                <Grid xs={4} textAlign="right">
                    <Button 
                        variant="contained" 
                        color="info"
                        href="/player"
                        startIcon={<ArrowBackIcon />}>
                        Takaisin listaan
                    </Button>
                </Grid>
            </Grid>

            <Card variant="outlined" sx={{ p:3, mb:3, borderRadius:3, boxShadow: 3}}>
                <Typography variant="h5">
                    Yleistiedot
                </Typography>
                <Box>
                    <Grid 
                        sx={{mt:3}}
                        container 
                        spacing={2} 
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid xs={2}>
                            Pelinumero
                        </Grid>
                        <Grid xs={10}>
                            {number}
                        </Grid>
                        <Grid xs={2}>
                            Pelipaikka
                        </Grid>
                        <Grid xs={10}>
                            {position}
                        </Grid>
                        <Grid xs={2}>
                            Vahvuudet
                        </Grid>
                        <Grid xs={10}>
                            {strengths}
                        </Grid>
                        <Grid xs={2}>
                            Kehityskohteet
                        </Grid>
                        <Grid xs={10}>
                            {targets}
                        </Grid>
                    </Grid>

                </Box>
            </Card>

            <Card variant="outlined" sx={{ p:3, mb:5, borderRadius:3, boxShadow: 3}}>
                <Typography variant="h5">
                    Testit
                </Typography>
                <Box>

                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    { tests && tests.map((test) => {
                                        return(
                                            <TableCell key={'head-'+test.id} align="center">
                                                <Moment format="DD.MM.YYYY">
                                                {test.date}
                                                </Moment>
                                            </TableCell>
                                        )
                                    })}
                                    <TableCell  align="center">
                                        KA / KA%
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { playertests.performances.map((performance) => {
                                    return(
                                        <TableRow key={performance.id}>
                                            <TableCell>{performance.title}</TableCell>
                                            { tests && tests.map((test:Test) => {
                                                return(
                                                    <TableCell key={'body-'+test.id} align="center">
                                                        <Typography variant="h6">
                                                            { getPlayerTestValue(test?.id, performance.id) }
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            { getTeamTestAverage(test?.id, performance.id )}
                                                        </Typography>
                                                    </TableCell>
                                                )
                                            })}
                                            <TableCell  align="center">
                                                <Typography variant="h6">
                                                    { getPlayerTestAverage(performance.id) }  / { getPlayerTestAveragePercent(performance?.id)}%
                                                </Typography>
                                                <Typography variant="body2">
                                                    { getTeamTestTotalAverage(performance.id) }
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Box>
            </Card>

            <Card variant="outlined" sx={{ p:3, mb:5, borderRadius:3, boxShadow: 3}}>
                <Typography variant="h5">
                    GPS
                </Typography>
                <Box>

                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    { gpss && gpss.map((gps) => {
                                        return(
                                            <TableCell key={'head-'+gps.id} align="center">
                                                <Moment format="DD.MM.YYYY">
                                                {gps.date}
                                                </Moment>
                                            </TableCell>
                                        )
                                    })}
                                    <TableCell  align="center">
                                        KA / KA %
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { playertests.gps.map((gps) => {
                                    return(
                                        <TableRow key={gps.id}>
                                            <TableCell>{gps.title}</TableCell>
                                            { gpss && gpss.map((gpsData:GPS) => {
                                                return(
                                                    <TableCell key={'body-'+gpsData.id} align="center">
                                                        <Typography variant="h6">
                                                            { getPlayerGPSValue(gpsData?.id, gps.id) }
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            { getTeamGPSAverage(gpsData?.id, gps.id) } 
                                                        </Typography>
                                                    </TableCell>
                                                )
                                            })}
                                            <TableCell  align="center">
                                                <Typography variant="h6">
                                                    { getPlayerGPSAverage(gps.id) } / { getPlayerGPSAveragePercent(gps?.id) }%
                                                </Typography>
                                                <Typography variant="body2">
                                                    { getTeamGPSTotalAverage(gps.id) }
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Box>
            </Card>

        </Container>
    )

}

export default PlayerForm;