import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import setupInterceptors from './services/setupInterceptors';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const theme = createTheme({
  palette: {
    background: {
      default: "#F3F3F3"
    }
  }
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

setupInterceptors(store);
reportWebVitals();