import { GPS, GPSData } from 'GPSModels';
import http from './http';

class GPSService {

  async getGPSs() {
    return http
      .get('api/gps')
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }

  async getGpssByPlayer(playerId: string) {
    return http
      .get('api/gps/player/'+playerId)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

  async getGPS(id: string) {
    return http
      .get('api/gps/'+id)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }
  
  async createGPS(gps:GPS) {
    return http
      .post('api/gps', gps)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

  async updateGPS(gps:GPS) {
    return http
      .put('api/gps/'+gps.id, gps)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

  async createGPSData(gpsData:GPSData) {
    return http
      .post('api/gps/gps-data', gpsData)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

}

export default new GPSService();
