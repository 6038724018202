import { User } from 'UserModels';
import http from './http';

class UserService {

  async getUsers() {
    return http
      .get('api/user')
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }

  async getUser(id: string) {
    return http
      .get('api/user/'+id)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }
  
  async createUser(user:User) {
    return http
      .post('api/user', user)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

  async updateUser(user:User) {
    return http
      .put('api/user/'+user.id, user)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

  async getProfile() {
    return http
      .get('api/user/profile')
      .then((response) => {
        return response.data;
      });
  }  

  async updateProfile(user:User) {
    return http
      .put('api/user/profile', user)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

}

export default new UserService();
