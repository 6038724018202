import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import userService from '../../services/user.service';
import { User, UserState } from 'UserModels';

const initialState: UserState = {
  users: [],
  user: undefined,
  profile: undefined
};

export const getUsersAsync = createAsyncThunk<UserState>(
  'user/getusers',
  async () => {
    try {
      const response = await userService.getUsers();
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const getUserAsync = createAsyncThunk(
  'user/getUser',
  async (userId: string) => {
    try {
      const response = await userService.getUser(userId);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "user/updateUser",
  async (user: User) => {
    try {
      const response = await userService.updateUser(user);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const createUserAsync = createAsyncThunk(
  "user/createUser",
  async (user: User) => {
    try {
      const response = await userService.createUser(user);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const getProfileAsync = createAsyncThunk(
  'user/getProfile',
  async () => {
    try {
      const response = await userService.getProfile();
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const updateProfileAsync = createAsyncThunk(
  "user/updateProfile",
  async (user: User) => {
    try {
      const response = await userService.updateProfile(user);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAsync.fulfilled, (state, { payload }) => {
        state.users = payload;
      })
      .addCase(getUserAsync.fulfilled, (state, { payload }) => {
        state.user = payload;
      })      
      .addCase(getProfileAsync.fulfilled, (state, { payload }) => {
        state.profile = payload;
      })     
  } 
});


export default userSlice.reducer;