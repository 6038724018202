import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import teamReducer from '../features/team/team.slice';
import userReducer from '../features/user/user.slice';
import gameReducer from '../features/game/game.slice';
import playerReducer from '../features/player/player.slice';
import testReducers from '../features/test/test.slice';
import gpsReducers from '../features/gps/gps.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    team: teamReducer,
    user: userReducer,
    game: gameReducer,
    player: playerReducer,
    test: testReducers,
    GPS: gpsReducers
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;