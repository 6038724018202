import { Card, Button, TextField, Select, MenuItem, Typography, Tab } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Container } from "@mui/system";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { createGameAsync, updateGameAsync } from "./game.slice";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import gameService from "../../services/game.service";
import datamodel from "../../services/schemas/gamestatistics";
import NumberValueInput from "../../components/NumberInput";
import { getUsersAsync } from "../user/user.slice";
import { getTeamsAsync } from "../team/team.slice";
import dayjs from "dayjs";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import 'dayjs/locale/fi';
import updateLocale from 'dayjs/plugin/updateLocale';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const GameForm = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const editMode = ( params.id !== undefined );
 
    const { users } = useAppSelector( (state) => state.user );
    const { teams } = useAppSelector( (state) => state.team );   

    const [periodValue, setPeriodValue] = useState(datamodel.parameters.periods[0].id);

    const [formValue, setFormValue] = useState({
        name: "",
        date: "",
        coachId: "",
        teamId: "",
        result: "",
        data: {} as any
    });
    const { name, date, coachId, teamId, data, result } = formValue;

    dayjs.extend(updateLocale);
    dayjs.updateLocale('fi', {
        // Sunday = 0, Monday = 1.
        weekStart: 1,
    });

    useEffect(() => {
        if (params.id) {
          const loadTeam = async () => {
            try {
              const response = await gameService.getGame(params.id as string);
              setFormValue(response);
            } catch (error) {
              console.error(error);
            }
          };
          loadTeam();
        }
    }, [params.id]);

    useEffect(() => {
        dispatch( getUsersAsync() );
    }, [dispatch]);

    useEffect(() => {
        dispatch( getTeamsAsync() );
    }, [dispatch]);

    const handleChange = (e:any) => {
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.value
        });
    }

    const handleDateChange = (value:any) => {
        setFormValue({
            ...formValue,
            'date': value
        })
    }
 
    const handleValueChange = (periodId:string, categoryId:string, itemId:string, valueId:string, value:number | null) => {
        var variable = periodId+"."+categoryId+"."+itemId+"."+valueId;
        setFormValue({
            ...formValue,
            data: { 
                ...formValue.data,
                [variable]: value
            }
        })
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setPeriodValue(newValue);
    };    

    const getDataValue = (periodId:string, categoryId:string, itemId:string, valueId:string) => {
        const variable = periodId+"."+categoryId+"."+itemId+"."+valueId;
        return formValue.data[variable] || 0;
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if( params.id ){
            dispatch(
                updateGameAsync({
                    id: params.id,
                    name: formValue.name,
                    coachId: formValue.coachId,
                    date: formValue.date,
                    teamId: formValue.teamId,
                    data: formValue.data,
                    result: formValue.result
                })
            )
            .unwrap()
            .then(() => {
              //navigate('/game');
              //window.location.reload();
            })
            .catch(() => {});
            
        } else {
            dispatch(
                createGameAsync({
                    name: formValue.name,
                    coachId: formValue.coachId,
                    date: formValue.date,
                    teamId: formValue.teamId,
                    data: formValue.data,
                    result: formValue.result
                })
            )
            .unwrap()
            .then((data) => {
              navigate('/game/edit/'+data.id);
              window.location.reload();
            })
            .catch(() => {});
        }
    }

    return (
        <Container maxWidth="xl">

            <Card variant="outlined" sx={{ p:3, my:5, mb:5, borderRadius:3, boxShadow: 3}}>
                <Box component="form" onSubmit={handleSubmit}>                       

                    <Grid container spacing={2}>
                        <Grid xs={3}>
                            Ottelu
                        </Grid>
                        <Grid xs={3}>
                            Valmentaja
                        </Grid>
                        <Grid xs={2}>
                            Joukkue
                        </Grid>
                        <Grid xs={2}>
                            Päivämäärä
                        </Grid>
                        <Grid xs={2}>
                            Tulos
                        </Grid>
                    </Grid>
                    
                    <Grid container spacing={2}>
                        <Grid xs={3}>
                            <TextField 
                                variant="outlined" 
                                size="small"
                                name="name"
                                fullWidth={true}
                                value={name}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={3}>
                            <Select
                                variant="outlined"
                                size="small"
                                name="coachId"
                                defaultValue=""
                                value={coachId}
                                fullWidth={true}
                                onChange={handleChange}
                                >
                                {users.map((user:any) => {
                                    return(
                                        <MenuItem 
                                            key={user.id}
                                            value={user.id}>
                                            {user.email} ({user.firstname} {user.lastname})
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Grid xs={2}>
                            <Select
                                variant="outlined"
                                size="small"
                                name="teamId"
                                defaultValue=""
                                value={teamId}
                                fullWidth={true}
                                onChange={handleChange}
                                >
                                {teams.map((team:any) => {
                                    return(
                                        <MenuItem 
                                            key={team.id}
                                            value={team.id}>
                                            {team.title}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Grid xs={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi">
                                <DatePicker
                                    name="date"
                                    format="DD.MM.YYYY"
                                    value={dayjs(date)}
                                    defaultValue={dayjs()}
                                    slotProps={{ 
                                        textField: { 
                                            fullWidth: true,
                                            size: "small"
                                        }
                                    }}
                                    onChange={handleDateChange} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid xs={2}>
                            <TextField 
                                variant="outlined" 
                                size="small"
                                name="result"
                                value={result}
                                fullWidth={true}
                                onChange={handleChange} />
                        </Grid>
                    </Grid>
                    
                    { editMode &&

                    <>
                        <TabContext value={periodValue}>

                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange} aria-label="basic tabs example">
                                {datamodel.parameters.periods.map((period) => {
                                    return(
                                        <Tab key={period.id} label={period.title} value={period.id} />
                                    )
                                })}
                                </TabList>
                            </Box>

                            {datamodel.parameters.periods.map((period) => {
                                return(
                                    <TabPanel 
                                        key={period.id} 
                                        value={period.id}
                                        sx={{ pl: 0, pr: 0 }}>
                                        <Grid container spacing={1} textAlign="center">
                                        {datamodel.categories.map((category) => {
                                            return(
                                                <Grid key={category.id} xs={12} sm={6}>
                                                    <Typography variant="h5">{category.title}</Typography>
                                                    {category.items.map((item) => {
                                                        return(
                                                            <Box key={item.id} marginTop={1} padding={1} style={{backgroundColor:'#eee'}}>
                                                                <Typography fontWeight={900}>{item.title}</Typography>
                                                                <Grid container spacing={2} textAlign="center">
                                                                {item.values.map((value) => {
                                                                    return(
                                                                        <Grid xs={6} key={value.id}>
                                                                            {value.title}
                                                                            <NumberValueInput
                                                                                min={0}
                                                                                readOnly={true}
                                                                                value={getDataValue(period.id, category.id, item.id, value.id)}
                                                                                defaultValue={0}
                                                                                onChange={(e, val) => {
                                                                                    e.preventDefault();
                                                                                    handleValueChange(period.id, category.id, item.id, value.id, val);
                                                                                }} />
                                                                        </Grid>
                                                                    )
                                                                })}
                                                                </Grid>
                                                            </Box>
                                                        )
                                                    })}
                                                </Grid>
                                            )
                                        })}
                                        </Grid>
                                    </TabPanel>
                                )
                            })}
                        </TabContext>
                    </>
                    }

                        <Grid container spacing={2}>
                            <Grid xs={12} textAlign="right">
                                <Button 
                                    variant="contained" 
                                    color="success"
                                    type="submit"
                                    startIcon={<SaveIcon />}>
                                    { editMode ? 'Tallenna' : 'Luo uusi' }
                                </Button>
                                <Button 
                                    href="/game"
                                    style={{marginLeft:10}}
                                    variant="contained" 
                                    color="info"
                                    startIcon={<ArrowBackIcon />}>
                                    { editMode ? 'Listaan' : 'Peruuta' }
                                </Button>
                            </Grid>
                        </Grid>

                </Box>
            </Card>

        </Container>
    )

}

export default GameForm;