import React from 'react';
import { Login } from './features/auth/login';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/privateRoute';
//import { Register } from './features/auth/register';
import Layout from './layouts/Layout';
import Game from './features/game/game';
import GameForm from './features/game/gameForm';
import StatisticsPage from './features/game/statistics';
import User from './features/user/user';
import Profile from './features/user/profile';
import UserForm from './features/user/userForm';
import Team from './features/team/team';
import TeamForm from './features/team/teamForm';
import Player from './features/player/player';
import PlayerView from './features/player/playerView';
import PlayerForm from './features/player/playerForm';
import Test from './features/test/test';
import TestForm from './features/test/testForm';
import GPS from './features/gps/gps';
import GPSForm from './features/gps/gpsForm';

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route index element={<Game />} />
          <Route path="/game">
            <Route index element={<Game />} />
            <Route path="/game/create" element={<GameForm />} />
            <Route path="/game/edit/:id" element={<GameForm />} />
            <Route path="/game/statistics" element={<StatisticsPage />} />
          </Route>
          <Route path="/player">
              <Route index element={<Player />} />
              <Route path="/player/create" element={<PlayerForm />} />
              <Route path="/player/:id" element={<PlayerView />} />
              <Route path="/player/edit/:id" element={<PlayerForm />} />
          </Route>
          <Route path="/test">
              <Route index element={<Test />} />
              <Route path="/test/create" element={<TestForm />} />
              <Route path="/test/edit/:id" element={<TestForm />} />
          </Route>      
          <Route path="/gps">
              <Route index element={<GPS />} />
              <Route path="/gps/create" element={<GPSForm />} />
              <Route path="/gps/edit/:id" element={<GPSForm />} />
          </Route>  
          <Route path="/team">
              <Route index element={<Team />} />
              <Route path="/team/create" element={<TeamForm />} />
              <Route path="/team/edit/:id" element={<TeamForm />} />
          </Route>
          <Route path="/user">
              <Route index element={<User />} />
              <Route path="/user/profile" element={<Profile />} />
              <Route path="/user/create" element={<UserForm />} />
              <Route path="/user/edit/:id" element={<UserForm />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        {/*<Route path="/register" element={<Register />} />*/}
      </Routes>
    </div>
  );
}

export default App;