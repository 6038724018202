import { Performance, PerformanceData } from 'TestModels';
import http from './http';

class PerformanceService {

    async createPerformance(performance:Performance) {
        return http
          .post('api/test/performance', performance)
          .then((response) => {
            return response.data;
          })
          .catch(e => {
            console.log(e)
          });
    }  

    async createPerformanceData(performanceData:PerformanceData) {
        return http
        .post('api/test/performance-data', performanceData)
        .then((response) => {
            return response.data;
        })
        .catch(e => {
            console.log(e)
        });
    }    

}

export default new PerformanceService();
