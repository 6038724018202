import { Button, Card, Container, MenuItem, Select, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getTeamsAsync } from "../team/team.slice";
import { searchGamesAsync } from "./game.slice";
import datamodel from "../../services/schemas/gamestatistics";
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import { Search } from "GameModels";
import 'dayjs/locale/fi';
import updateLocale from 'dayjs/plugin/updateLocale';

const StatisticsPage = () => {

    const dispatch = useAppDispatch();

    const { teams } = useAppSelector( (state) => state.team );  
    const { searchResults } = useAppSelector( state => state.game );

    const [formValue, setFormValue] = useState({
        startDate: "",
        endDate: "",
        teamId: ""
    });
    const { startDate, endDate, teamId } = formValue;

    dayjs.extend(updateLocale);
    dayjs.updateLocale('fi', {
        // Sunday = 0, Monday = 1.
        weekStart: 1,
    });

    useEffect(() => {
        dispatch( getTeamsAsync() );
        dispatch( searchGamesAsync({start:'', end:'', team:''}) );
    }, [dispatch]);

    const handleChange = (e:any) => {
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.value
        });
    }    

    const handleDateChange = (value:any, name:any) => {
        setFormValue({
            ...formValue,
            [name]: value
        })
    }    

    const handleSearch = () => {
        const search:Search = {
            start: startDate,
            end: endDate,
            team: teamId
        }
        dispatch( searchGamesAsync(search) );
    }

    return (
        <Container maxWidth="xl">

            <Grid container sx={{my:5}}>
                <Grid xs={8}>
                    <Typography variant="h4">
                        Tilastot
                    </Typography>
                </Grid>
                <Grid xs={4} textAlign="right">
                    <Button 
                        startIcon={<SportsSoccerIcon />}
                        variant="contained" 
                        color="info"
                        href="/game">
                        Ottelut
                    </Button>
                </Grid>
            </Grid>

            <Card variant="outlined" sx={{ p:3, mb:5, borderRadius:3, boxShadow: 3}}>

                <Grid container spacing={2}>
                    <Grid xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi">
                            <DatePicker
                                name="startDate"
                                format="DD.MM.YYYY"
                                value={dayjs(startDate)}
                                defaultValue={dayjs()}
                                slotProps={{ 
                                    textField: { 
                                        fullWidth: true,
                                        size: "small"
                                    }
                                }}
                                onChange={(value) => {handleDateChange(value, 'startDate')}} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi">
                            <DatePicker
                                name="endDate"
                                format="DD.MM.YYYY"
                                value={dayjs(endDate)}
                                defaultValue={dayjs()}
                                slotProps={{ 
                                    textField: { 
                                        fullWidth: true,
                                        size: "small"
                                    }
                                }}
                                onChange={(value) => {handleDateChange(value, 'endDate')}} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid xs={2}>
                        <Select
                            variant="outlined"
                            size="small"
                            name="teamId"
                            defaultValue=""
                            value={teamId}
                            fullWidth={true}
                            onChange={handleChange}
                            >
                            {teams.map((team:any) => {
                                return(
                                    <MenuItem 
                                        key={team.id}
                                        value={team.id}>
                                        {team.title}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Grid>
                    <Grid xs={2}>
                        <Button 
                            variant="contained" 
                            color="info"
                            onClick={handleSearch}>
                            Hae tilastot
                        </Button>
                    </Grid>
                </Grid>

                <Grid container sx={{mt:5}}>

                { datamodel.categories.map((category) => {
                    return(
                        <Container key={category.id}>

                        <Typography variant="h5">
                            {category.title}
                        </Typography>

                        { category.items.map((item) => {

                            // series
                            let series:Array<any> = [];
                            //console.log(searchResults);
                            searchResults?.forEach((game:any) => {
                                let seriesData:Array<any> = [];
                                datamodel.parameters.periods?.forEach(period => {
                                    item.values?.forEach(value => {
                                        const valueKey = period.id+"."+category.id+"."+item.id+"."+value.id;
                                        const data = game.data[valueKey] || 0;
                                        seriesData.push(data);
                                    });
                                });
                                series.push({
                                    name: game.name,
                                    data: seriesData
                                });
                            }) 

                            // categories
                            let values:Array<any> = [];
                            datamodel.parameters.periods?.forEach(period => {
                                item.values?.forEach(value => {
                                    values.push(period.title + " - " + value.title);
                                });
                            });

                            const chartOptions:ApexOptions = {
                                chart: {
                                  type: 'bar',
                                },
                                series: series,
                                xaxis: {
                                  categories: values
                                },
                            };

                            return (
                                <Grid xs={12} sx={{p:2}} key={item.id}>
                                    <Typography>
                                        {item.title}
                                    </Typography>
                                    <ReactApexChart
                                        options={chartOptions}
                                        series={chartOptions.series}
                                        type="bar"
                                        height={350}
                                    />
                                </Grid>
                            )
                            
                        })}
                        </Container>
                    )
                })}
                
                </Grid>

            </Card>

        </Container>
    )
}

export default StatisticsPage;