import { Card, Button, TextField, Typography, Divider } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Container } from "@mui/system";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { createTeamAsync, updateTeamAsync } from "./team.slice";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import teamService from "../../services/team.service";
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const TeamForm = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const editMode = ( params.id !== undefined );

    const [formValue, setFormValue] = useState({
        title: ""
    });
    const { title } = formValue;

    useEffect(() => {
        if (params.id) {
          const loadTeam = async () => {
            try {
              const response = await teamService.getTeam(params.id as string);
              setFormValue(response);
            } catch (error) {
              console.error(error);
            }
          };
          loadTeam();
        }
    }, [params.id]);


    const handleChange = (e:any) => {
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if( params.id ){
            dispatch(
                updateTeamAsync({
                    id: params.id,
                    title: formValue.title
                })
            )
            .unwrap()
            .then(() => {
              navigate('/team');
              window.location.reload();
            })
            .catch(() => {});
            
        } else {
            dispatch(
                createTeamAsync({
                    title: formValue.title
                })
            )
            .unwrap()
            .then(() => {
              navigate('/team');
              window.location.reload();
            })
            .catch(() => {});
        }
    }

    return (
        <Container maxWidth="xl">

            <Typography variant="h4" sx={{my:5}}>
                { editMode ? 'Muokkaa joukkuetta' : 'Luo uusi joukkue' }
            </Typography>

            <Card variant="outlined" sx={{ p:3, mb:5, borderRadius:3, boxShadow: 3}}>
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid 
                        container 
                        spacing={2} 
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid xs={6}>
                            Nimi
                        </Grid>
                        <Grid xs={6}>
                            <TextField 
                                variant="outlined" 
                                name="title"
                                fullWidth={true}
                                value={title}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={12}>
                            <Divider />
                        </Grid>
                        <Grid xs={6}>

                        </Grid>
                        <Grid xs={6}>
                            <Button 
                                variant="contained" 
                                color="success"
                                type="submit"
                                startIcon={<SaveIcon />}>
                                { editMode ? 'Tallenna' : 'Luo uusi' }
                            </Button>
                            <Button 
                                href="/team"
                                style={{marginLeft:10}}
                                variant="contained" 
                                color="info"
                                startIcon={<ArrowBackIcon />}>
                                Peruuta
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Card>

        </Container>
    )

}

export default TeamForm;