import { Team } from 'TeamModels';
import http from './http';

class TeamService {

  async getTeams() {
    return http
      .get('api/team')
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }

  async getTeam(id: string) {
    return http
      .get('api/team/'+id)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }
  
  async createTeam(team:Team) {
    return http
      .post('api/team', team)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

  async updateTeam(team:Team) {
    return http
      .put('api/team/'+team.id, team)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

}

export default new TeamService();
