const datamodel = {

    performances: [
        {
            id: 'mas',
            title: 'MAS'
        },
        {
            id: 'juoksu',
            title: '30m (s)'
        },
        {
            id: 'loikka',
            title: '3-Loikka (m)'
        },
        {
            id: 'ponnauttelu',
            title: 'Ponnauttelu 4x4x4 x 3'
        },
        {
            id: 'pujottelu',
            title: 'Pujottelu (s)'
        },
        {
            id: 'syotto',
            title: 'Syöttö (s)'
        }                                       
    ],

    gps: [
        {
            id: 'max_spd',
            title: 'Max spd',
            type: 'input',
            formula: '',
            variables: [],
            unit: 'km/h'
        },
        {
            id: 'ave_spd',
            title: 'Ave spd',
            type: 'formula',
            formula: 'totald / ( time / 60 )',
            variables: ['totald', 'time'],
            unit: 'km/h'
        },
        {
            id: 'sprint',
            title: 'Sprint',
            type: 'input',
            formula: '',
            variables: [],
            unit: 'kpl'
        },
        {
            id: 's10m',
            title: 'S/10m',
            type: 'formula',
            formula: 'sprint / time * 10',
            variables: ['sprint', 'time'],
            unit: 'kpl'
        },
        {
            id: 'accel',
            title: 'Accel',
            type: 'input',
            variables: [],
            unit: 'kpl'
        },
        {
            id: 'a10m',
            title: 'A/10m',
            type: 'formula',
            formula: 'accel / time * 10',
            variables: ['accel', 'time'],
            unit: 'kpl'
        },
        {
            id: 'decl',
            title: 'Decl',
            type: 'input',
            formula: '',
            variables: [],
            unit: 'kpl'
        },
        {
            id: 'd10m',
            title: 'D/10m',
            type: 'formula',
            formula: 'decl / time * 10',
            variables: ['decl', 'time'],
            unit: 'kpl'
        },
        {
            id: 'totald',
            title: 'Total D',
            type: 'input',
            formula: '',
            variables: [],
            unit: 'kn'
        },
        {
            id: 'km10m',
            title: 'km/10m',
            type: 'formula',
            formula: 'totald / time * 10',
            variables: ['totald', 'time'],
            unit: 'km'
        },
        {
            id: 'dz5',
            title: 'D Z5',
            type: 'input',
            formula: '',
            variables: [],
            unit: 'm'
        },
        {
            id: 'z510m',
            title: 'Z5/10m',
            type: 'formula',
            formula: 'dz5 / time * 10',
            variables: ['dz5', 'time'],
            unit: 'm'
        },
        {
            id: 'dz6',
            title: 'D Z6',
            type: 'input',
            formula: '',
            variables: [],
            unit: 'm'
        },
        {
            id: 'z610m',
            title: 'Z6/10m',
            type: 'formula',
            formula: 'dz6 / time * 10',
            variables: ['dz6', 'time'],
            unit: 'm'
        },
        {
            id: 'time',
            title: 'Time',
            type: 'input',
            formula: '',
            variables: [],
            unit: 'min'
        }

    ]

}

export default datamodel;