import { Card, Button, TextField, Select, MenuItem, Typography, Tab, Divider } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Container } from "@mui/system";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { createPlayerAsync, getPlayersAsync, updatePlayerAsync } from "./player.slice";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import playerService from "../../services/player.service";
import { getTeamsAsync } from "../team/team.slice";
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PlayerForm = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const editMode = ( params.id !== undefined );
 
    const { teams } = useAppSelector( (state) => state.team );   

    const [formValue, setFormValue] = useState({
        firstname: "",
        lastname: "",
        teamId: "",
        number: "",
        position: "",
        targets: "",
        strengths: ""
    });
    const { firstname, lastname, teamId, number, position, targets, strengths } = formValue;

    useEffect(() => {
        if (params.id) {
          const loadPlayer = async () => {
            try {
              const response = await playerService.getPlayer(params.id as string);
              setFormValue(response);
            } catch (error) {
              console.error(error);
            }
          };
          loadPlayer();
        }
    }, [params.id]);

    useEffect(() => {
        dispatch( getPlayersAsync() );
    }, [dispatch]);

    useEffect(() => {
        dispatch( getTeamsAsync() );
    }, [dispatch]);

    const handleChange = (e:any) => {
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if( params.id ){
            dispatch(
                updatePlayerAsync({
                    id: params.id,
                    firstname: formValue.firstname,
                    lastname: formValue.lastname,
                    teamId: formValue.teamId,
                    number: formValue.number,
                    position: formValue.position,
                    strengths: formValue.strengths,
                    targets: formValue.targets
                })
            )
            .unwrap()
            .then(() => {
              navigate('/player');
              window.location.reload();
            })
            .catch(() => {});
            
        } else {
            dispatch(
                createPlayerAsync({
                    firstname: formValue.firstname,
                    lastname: formValue.lastname,
                    teamId: formValue.teamId,
                    number: formValue.number,
                    position: formValue.position,
                    strengths: formValue.strengths,
                    targets: formValue.targets
                })
            )
            .unwrap()
            .then((data) => {
              navigate('/player');
              window.location.reload();
            })
            .catch(() => {});
        }
    }

    return (
        <Container maxWidth="xl">

            <Typography variant="h4" sx={{my:5}}>
                { editMode ? 'Muokkaa pleaajan tietoja' : 'Lisää uusi pelaaja' }
            </Typography>

            <Card variant="outlined" sx={{ p:3, mb:5, borderRadius:3, boxShadow: 3}}>
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid 
                        container 
                        spacing={2} 
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid xs={6}>
                            Etunimi
                        </Grid>
                        <Grid xs={6}>
                            <TextField 
                                variant="outlined" 
                                name="firstname"
                                fullWidth={true}
                                value={firstname}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={6}>
                            Sukunimi
                        </Grid>
                        <Grid xs={6}>
                            <TextField 
                                variant="outlined" 
                                name="lastname"
                                fullWidth={true}
                                value={lastname}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={6}>
                            Joukkue
                        </Grid>
                        <Grid xs={6}>
                            <Select
                                variant="outlined"
                                name="teamId"
                                defaultValue=""
                                value={teamId}
                                fullWidth={true}
                                onChange={handleChange}
                                >
                                {teams.map((team:any) => {
                                    return(
                                        <MenuItem 
                                            key={team.id}
                                            value={team.id}>
                                            {team.title}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Grid xs={6}>
                            Pelinumero
                        </Grid>
                        <Grid xs={6}>
                            <TextField 
                                variant="outlined" 
                                name="number"
                                fullWidth={true}
                                value={number}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={6}>
                            Pelipaikka
                        </Grid>
                        <Grid xs={6}>
                            <TextField 
                                variant="outlined" 
                                name="position"
                                fullWidth={true}
                                value={position}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={6}>
                            Vahvuudet
                        </Grid>
                        <Grid xs={6}>
                            <TextField 
                                multiline
                                variant="outlined" 
                                name="strengths"
                                fullWidth={true}
                                value={strengths}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={6}>
                            Kehityskohteet
                        </Grid>
                        <Grid xs={6}>
                            <TextField 
                                multiline
                                variant="outlined" 
                                name="targets"
                                fullWidth={true}
                                value={targets}
                                onChange={handleChange} />
                        </Grid>

                        <Grid xs={12}>
                            <Divider />
                        </Grid>
                        <Grid xs={6}>

                        </Grid>
                        <Grid xs={6}>
                            <Button 
                                variant="contained" 
                                color="success"
                                type="submit"
                                startIcon={<SaveIcon />}>
                                { editMode ? 'Tallenna' : 'Luo uusi' }
                            </Button>
                            <Button 
                                href="/player"
                                style={{marginLeft:10}}
                                variant="contained" 
                                color="info"
                                startIcon={<ArrowBackIcon />}>
                                Peruuta
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Card>


        </Container>
    )

}

export default PlayerForm;