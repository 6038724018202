import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import teamService from '../../services/team.service';
import { Team, TeamState } from 'TeamModels';

const initialState: TeamState = {
  teams: [],
  team: undefined
};

export const getTeamsAsync = createAsyncThunk<TeamState>(
  'team/getTeams',
  async () => {
    try {
      const response = await teamService.getTeams();
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const getTeamAsync = createAsyncThunk(
  'team/getTeam',
  async (teamId: string) => {
    try {
      const response = await teamService.getTeam(teamId);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const updateTeamAsync = createAsyncThunk(
  "team/updateTeam",
  async (team: Team) => {
    try {
      const response = await teamService.updateTeam(team);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const createTeamAsync = createAsyncThunk(
  "team/createTeam",
  async (team: Team) => {
    try {
      const response = await teamService.createTeam(team);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamsAsync.fulfilled, (state, { payload }) => {
        state.teams = payload;
      })
      .addCase(getTeamAsync.fulfilled, (state, { payload }) => {
        state.team = payload;
      })      
  } 
});

export default teamSlice.reducer;