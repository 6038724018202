import { Card, Button, TextField, Divider, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Container } from "@mui/system";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { createUserAsync, updateUserAsync } from "./user.slice";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import userService from "../../services/user.service";
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const UserForm = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const editMode = ( params.id !== undefined );

    const [formValue, setFormValue] = useState({
        email: "",
        password: "",
        firstname: "",
        lastname: ""
    });
    const { email, password, firstname, lastname } = formValue;

    useEffect(() => {
        if (params.id) {
          const loadUser = async () => {
            try {
              const response = await userService.getUser(params.id as string);
              setFormValue(response);
            } catch (error) {
              console.error(error);
            }
          };
          loadUser();
        }
    }, [params.id]);


    const handleChange = (e:any) => {
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if( params.id ){

            dispatch(
                updateUserAsync({
                    id: params.id,
                    email: formValue.email,
                    password: formValue.password,
                    firstname: formValue.firstname,
                    lastname: formValue.lastname
                })
            )
            .unwrap()
            .then(() => {
              navigate('/user');
              window.location.reload();
            })
            .catch(() => {});
            
        } else {

            dispatch(
                createUserAsync({
                    email: formValue.email,
                    password: formValue.password,
                    firstname: formValue.firstname,
                    lastname: formValue.lastname                    
                })
            )
            .unwrap()
            .then(() => {
              navigate('/user');
              window.location.reload();
            })
            .catch(() => {});
        }
    }

    return (
        <Container maxWidth="xl">
            
            <Typography variant="h4" sx={{my:5}}>
                { editMode ? 'Muokkaa käyttäjää' : 'Luo uusi käyttäjä' }
            </Typography>

            <Card variant="outlined" sx={{ p:3, mb:5, borderRadius:3, boxShadow: 3}}>

                <Box component="form" onSubmit={handleSubmit}>
                    <Grid 
                        container 
                        spacing={2} 
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid xs={6}>
                            Sähköpostiosoite
                        </Grid>
                        <Grid xs={6}>
                            <TextField 
                                variant="outlined" 
                                name="email"
                                fullWidth={true}
                                value={email}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={6}>
                            { editMode ? 'Uusi salasana' : 'Salasana' }
                        </Grid>
                        <Grid xs={6}>
                            <TextField 
                                variant="outlined" 
                                name="password"
                                type="password"
                                fullWidth={true}
                                value={password}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={6}>
                            Etunimi
                        </Grid>
                        <Grid xs={6}>
                            <TextField 
                                variant="outlined" 
                                name="firstname"
                                fullWidth={true}
                                value={firstname}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={6}>
                            Sukunimi
                        </Grid>
                        <Grid xs={6}>
                            <TextField 
                                variant="outlined" 
                                name="lastname"
                                fullWidth={true}
                                value={lastname}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={12}>
                            <Divider />
                        </Grid>
                        <Grid xs={6}>
                            
                        </Grid>
                        <Grid xs={6}>
                            <Button 
                                variant="contained" 
                                color="success"
                                type="submit"
                                startIcon={<SaveIcon />}>
                                { editMode ? 'Tallenna' : 'Luo uusi' }
                            </Button>
                            <Button 
                                href="/user"
                                style={{marginLeft:10}}
                                variant="contained" 
                                color="info"
                                startIcon={<ArrowBackIcon />}>
                                Peruuta
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

            </Card>

        </Container>
    )

}

export default UserForm;