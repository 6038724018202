const datamodel = {
    
    parameters: {
        periods: [
            {index: 1, id: 'first_half', title: '1. puoliaika'},
            {index: 2, id: 'second_half', title: '2. puoliaika'}
        ]
    },
    categories: [
        {
            id: 'attack',
            title: 'Hyökkäys',
            items: [
                { 
                    id: 'attack_middle', 
                    title: 'Avaus rakentelu keskeltä',
                    values: [
                        { id: 'successful', title: 'onnistunut' },
                        { id: 'unsuccessful', title: 'epäonnistunut' }
                    ]
                },
                { 
		            id: 'attack_side',
		            title: 'Avaus rakentelu laidasta',
                    values: [
                        { id: 'successful', title: 'onnistunut' },
                        { id: 'unsuccessful', title: 'epäonnistunut' }
                    ]
		        },
                { 
                    id: 'breakaway_middle', 
                    title: 'Muratautuminen keskeltä',
                    values: [
                        { id: 'successful', title: 'onnistunut' },
                        { id: 'unsuccessful', title: 'epäonnistunut' }
                    ]
                },
                { 
                    id: 'breakaway_side', 
                    title: 'Murtautuminen laidasta' ,
                    values: [
                        { id: 'successful', title: 'onnistunut' },
                        { id: 'unsuccessful', title: 'epäonnistunut' }
                    ]
                },
                { 
                    id: 'into_box', 
                    title: 'Boxiin pääsy',
                    values: [
                        { id: 'amount', title: 'Määrä' }
                    ]
                },
                { 
                    id: 'shoot', 
                    title: 'Laukaukset',
                    values: [
                        { id: 'ontarget', title: 'Kohti' },
                        { id: 'offtarget', title: 'Ohi' }
                    ]
                },
                { 
                    id: 'corner', 
                    title: 'Kulmat',
                    values: [
                        { id: 'amount', title: 'Määrä' }
                    ]
                }
            ]
        },
        {
            id: 'defence',
            title: 'Puolustus',
            items: [
                { 
                    id: 'block_middle', 
                    title: 'Avaus rakentelun estäminen keskeltä', 
                    values: [
                        { id: 'successful', title: 'onnistunut' },
                        { id: 'unsuccessful', title: 'epäonnistunut' }
                    ]
                },
                { 
                    id: 'block_side', 
                    title: 'Avaus rakentelun estäminen laidasta',
                    values: [
                        { id: 'successful', title: 'onnistunut' },
                        { id: 'unsuccessful', title: 'epäonnistunut' }
                    ] 
                },
                { 
                    id: 'breakaway_block_middle', 
                    title: 'Muratautumisen estäminen keskeltä', 
                    values: [
                        { id: 'successful', title: 'onnistunut' },
                        { id: 'unsuccessful', title: 'epäonnistunut' }
                    ] 
                },
                { 
                    id: 'breakaway_block_side', 
                    title: 'Murtautumisen estäminen laidasta', 
                    values: [
                        { id: 'successful', title: 'onnistunut' },
                        { id: 'unsuccessful', title: 'epäonnistunut' }
                    ] 
                },
                { 
                    id: 'into_box_defence', 
                    title: 'Boxiin pääsy', 
                    values: [
                        { id: 'amount', title: 'Määrä' }
                    ] 
                },
                { 
                    id: 'shoot_defence', 
                    title: 'Laukaukset',
                    values: [
                        { id: 'ontarget', title: 'Kohti' },
                        { id: 'offtarget', title: 'Ohi' }
                    ]
                },
                { 
                    id: 'corner_defence', 
                    title: 'Kulmat',
                    values: [
                        { id: 'amount', title: 'Määrä' }
                    ] 
                }
            ]
        }
    ]

};

export default datamodel;