import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import playerService from '../../services/player.service';
import { Player, PlayerState } from 'PlayerModels';

const initialState: PlayerState = {
  players: [],
  player: undefined
};


export const getPlayersAsync = createAsyncThunk<PlayerState>(
  'player/getPlayers',
  async () => {
    try {
      const response = await playerService.getPlayers();
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const getPlayerAsync = createAsyncThunk(
  'player/getPlayer',
  async (playerId: string) => {
    try {
      const response = await playerService.getPlayer(playerId);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const updatePlayerAsync = createAsyncThunk(
  "player/updatePlayer",
  async (player: Player) => {
    try {
      const response = await playerService.updatePlayer(player);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const createPlayerAsync = createAsyncThunk(
  "player/createPlayer",
  async (player: Player) => {
    try {
      const response = await playerService.createPlayer(player);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlayersAsync.fulfilled, (state, { payload }) => {
        state.players = payload;
      })
      .addCase(getPlayerAsync.fulfilled, (state, { payload }) => {
        state.player = payload;
      }) 
  } 
});


export default playerSlice.reducer;