import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import testService from '../../services/test.service';
import performanceService from '../../services/performance.service';
import { Performance, PerformanceData, Test, TestState } from 'TestModels';

const initialState: TestState = {
  tests: [],
  test: undefined
};

export const getTestsAsync = createAsyncThunk<TestState>(
  'test/getTests',
  async () => {
    try {
      const response = await testService.getTests();
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const getTestAsync = createAsyncThunk(
  'test/getTest',
  async (testId: string) => {
    try {
      const response = await testService.getTest(testId);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const updateTestAsync = createAsyncThunk(
  "test/updateTest",
  async (test: Test) => {
    try {
      const response = await testService.updateTest(test);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const createTestAsync = createAsyncThunk(
  "test/createTest",
  async (test: Test) => {
    try {
      const response = await testService.createTest(test);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const createPerformanceAsync = createAsyncThunk(
  "test/createPerformance",
  async (performance: Performance) => {
    try {
      const response = await performanceService.createPerformance(performance);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const createPerformanceDataAsync = createAsyncThunk(
  "test/createPerformanceData",
  async (performanceData: PerformanceData) => {
    try {
      const response = await performanceService.createPerformanceData(performanceData);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const testSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTestsAsync.fulfilled, (state, { payload }) => {
        state.tests = payload;
      })
      .addCase(getTestsAsync.rejected, (state, { payload }) => {
        //state.tests = payload;
      })
      .addCase(getTestAsync.fulfilled, (state, { payload }) => {
        state.test = payload;
      })      
  } 
});

export default testSlice.reducer;