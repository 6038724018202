import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import GPSService from '../../services/gps.service';
import { GPS, GPSData, GPSState } from 'GPSModels';

const initialState: GPSState = {
  GPSs: [],
  GPS: undefined
};

export const getGPSsAsync = createAsyncThunk<GPSState>(
  'gps/getGPSs',
  async () => {
    try {
      const response = await GPSService.getGPSs();
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const getGPSAsync = createAsyncThunk(
  'gps/getGPS',
  async (gpsId: string) => {
    try {
      const response = await GPSService.getGPS(gpsId);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const updateGPSAsync = createAsyncThunk(
  "gps/updateGPS",
  async (gps: GPS) => {
    try {
      const response = await GPSService.updateGPS(gps);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const createGPSAsync = createAsyncThunk(
  "gps/createGPS",
  async (gps: GPS) => {
    try {
      const response = await GPSService.createGPS(gps);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const createGPSDataAsync = createAsyncThunk(
  "gps/createGPSData",
  async (gpsData: GPSData) => {
    try {
      const response = await GPSService.createGPSData(gpsData);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const gpsSlice = createSlice({
  name: 'gps',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGPSsAsync.fulfilled, (state, { payload }) => {
        state.GPSs = payload;
      })
      .addCase(getGPSsAsync.rejected, (state, { payload }) => {
        //state.tests = payload;
      })
      .addCase(getGPSAsync.fulfilled, (state, { payload }) => {
        state.GPS = payload;
      })      
  } 
});

export default gpsSlice.reducer;