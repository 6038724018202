import { PerformanceData, Test } from 'TestModels';
import http from './http';

class TestService {

  async getTests() {
    return http
      .get('api/test')
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }

  async getTestsByPlayer(playerId: string) {
    return http
      .get('api/test/player/'+playerId)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

  async getTest(id: string) {
    return http
      .get('api/test/'+id)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }
  
  async createTest(test:Test) {
    return http
      .post('api/test', test)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

  async updateTest(test:Test) {
    return http
      .put('api/test/'+test.id, test)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

  async savePerformanceData(performanceData:PerformanceData) {
    return http
      .post('api/test/performance-data', performanceData)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }    

}

export default new TestService();
