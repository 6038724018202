import { Button, AppBar, CssBaseline, Toolbar, Typography, Grid, makeStyles, styled, Box, IconButton, Menu, MenuItem, Divider, ListItemIcon, Avatar } from "@mui/material"
import React from "react"
import { NavLink as NavLinkBase, Outlet, useNavigate } from "react-router-dom"
import { useAppDispatch } from "../store/hooks"
import { logoutAsync } from "../features/auth/authSlice"
import AccountMenu from "../components/AccountMenu"
import { GridMenuIcon } from "@mui/x-data-grid"
import { Logout } from "@mui/icons-material"

const NavLink = styled(NavLinkBase)`
    &.active { 
        background-color: rgba(0, 0, 0, 0.2);
    }
    &:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
`

const Layout = () => {
    
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const handleLogoutClick = async () => {
        dispatch(logoutAsync());
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleProfileClick = () => {
      navigate('/user/profile');
    };

    const pages = [
        {
            title: 'Ottelut',
            link: '/game'
        },
        {
            title: 'Testit',
            link: '/test'
        },
        {
            title: 'GPS',
            link: '/gps'
        },
        {
            title: 'Pelaajat',
            link: '/player'
        },
        {
            title: 'Joukkueet',
            link: '/team'
        },
        {
            title: 'Käyttäjät',
            link: '/user'
        },        
    ]

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="static" color="primary" elevation={3}>
                <Toolbar>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <Typography variant="h6" color="inherit">
                                Tilastointi
                            </Typography>
                        </Grid>
                        <Grid item xs={10} style={{textAlign:'right'}}>
                            <Box sx={{ display: { xs: 'none', md: 'block' }}}>
                                {pages.map((page, key) => (
                                    <Button 
                                        key={key}
                                        color="inherit" 
                                        to={page.link} 
                                        component={NavLink}>
                                        {page.title}
                                    </Button>
                                ))}
                                <AccountMenu />
                            </Box>
                            <Box sx={{ display: { xs: 'block', md: 'none' }}}>
                                <>
                                    <IconButton
                                        color="inherit"
                                        onClick={handleClick}>
                                        <GridMenuIcon />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        open={open}
                                        keepMounted
                                        onClose={handleClose}
                                        onClick={handleClose}                                       
                                        anchorEl={anchorEl}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        sx={{
                                            display: { xs: 'block', md: 'none' },
                                        }}
                                        >
                                        {pages.map((page) => (
                                            <MenuItem 
                                                key={page.title} 
                                                to={page.link} 
                                                component={NavLink}>
                                                <Typography 
                                                    textAlign="center">
                                                    {page.title}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                        <MenuItem onClick={handleProfileClick}>
                                            Profile
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem onClick={handleLogoutClick}>
                                            Logout
                                        </MenuItem>
                                    </Menu>
                                </>
                            </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Outlet />
        </React.Fragment>
    )
}
export default Layout