import { Player } from 'PlayerModels';
import http from './http';

class PlayerService {

  async getPlayers() {
    return http
      .get('api/player')
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }

  async getPlayer(id: string) {
    return http
      .get('api/player/'+id)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }
  
  async createPlayer(player:Player) {
    return http
      .post('api/player', player)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

  async updatePlayer(player:Player) {
    return http
      .put('api/player/'+player.id, player)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

}

export default new PlayerService();
