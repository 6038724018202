import { 
    Card,
    Button, 
    TextField,
    Typography, 
    Select, 
    MenuItem
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Container } from "@mui/system";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { createGPSAsync, updateGPSAsync, createGPSDataAsync } from "./gps.slice";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import gpsService from "../../services/gps.service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { getPlayersAsync } from "../player/player.slice";
import playertests from "../../services/schemas/playerstatistics";
import { GPS } from "GPSModels";
import { Parser } from "expr-eval";
import {
    DataGrid, 
    GridColDef, 
    GridRowModel, 
    useGridApiRef 
} from '@mui/x-data-grid';
import 'dayjs/locale/fi';
import updateLocale from 'dayjs/plugin/updateLocale';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';


const GPSForm = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const apiRef = useGridApiRef();
    const sumApiRef = useGridApiRef();
    const params = useParams();
    const parser:Parser = new Parser();
    const editMode = ( params.id !== undefined );
    const [formValue, setFormValue] = useState<GPS>({
        title: "",
        date: "",
        data: []
    });
    const {title, date, data} = formValue;
    const [playerId, setPlayerId] = useState('');
    const [rows, setRows] = useState<object[]>([]);
    const [footerData, setFooterData] = useState<object[]>([]);
    const { players } = useAppSelector( (state) => state.player );

    dayjs.extend(updateLocale);
    dayjs.updateLocale('fi', {
        // Sunday = 0, Monday = 1.
        weekStart: 1,
    });

    useEffect(() => {
        dispatch( getPlayersAsync() );
    }, []);

    useEffect(() => {
        if (params.id) {
            loadGPS();
        }
    }, [params.id]);

    useEffect(() => {
        if( params.id ){
            apiRef.current.subscribeEvent('scrollPositionChange', (params) => {
                sumApiRef.current.scroll({left:params.left})
            });      
        } 
    }, [apiRef]);   

    const isPlayerSelected = (playerId:string) => {
        return data.findIndex(p => p.player?.id === playerId) !== -1
    }

    const loadGPS = async () => {
        try {
            const response = await gpsService.getGPS(params.id as string);
            setFormValue(response);
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (e:any) => {     
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.value as number
        });
    }
    
    const reCalculate = () => {
        const updatedData = data.map(row => {
            playertests.gps?.forEach(gpsData => {
                if(gpsData.type == 'formula'){
                    let varObj = {};
                    gpsData.variables?.forEach(v => {
                        let val = row.data[v as keyof object] || 0;
                        varObj[v as keyof object] = val;
                    });
                    const formula = gpsData.formula || "";
                    let expr = parser.parse(formula);
                    const res = expr.evaluate(varObj);
                    row.data = {
                        ...row.data,
                        [gpsData.id]: isNaN(res) || res == 'Infinity' ? "" : Math.round(res * 100) / 100
                    }
                }
            })
            return row;
        });
        setFormValue({
            ...formValue,
            data: updatedData
        })
    }

    const handlePlayerSelect = (e:any) => {
        setPlayerId(e.target.value);
    }

    const handleDateChange = (value:any) => {
        setFormValue({
            ...formValue,
            'date': value
        })
    }

    const addRowHandler = () => {
        const gpsId:string = params.id as string;
        if( playerId && gpsId ){
            dispatch(
                createGPSDataAsync({
                    playerId: playerId,
                    gpsId: gpsId,
                    data: []
                })
            )
            .unwrap()
            .then((data) => {
                navigate('/gps/edit/'+params.id);
                window.location.reload();
            })
            .catch(() => {});
        }
        setPlayerId('');
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if( params.id ){
            reCalculate();
            dispatch(
                updateGPSAsync({
                    id: params.id,
                    title: title,
                    date: date,
                    data: data
                })
            )
            .unwrap()
            .then(() => {
                navigate('/gps/edit/'+params.id);
                window.location.reload();
            })
            .catch(() => {});
            
        } else {
            dispatch(
                createGPSAsync({
                    title: title,
                    date: date,
                    data: data
                })
            )
            .unwrap()
            .then((data) => {
              navigate('/gps/edit/'+data.id);
              window.location.reload();
            })
            .catch(() => {});
        }
    }

    const processRowUpdate = (newRow: GridRowModel) => {

        const updatedData = data.map(row => {
            if (row.player?.id === newRow.id) {
                let {id, name, ...newData} = newRow;
                row.data = {
                    ...newData,
                }
            }
            return row;
        });
        setFormValue({
            ...formValue,
            data: updatedData
        });  

        // recalc
        reCalculate();

        return newRow;
    };
    
    /***
     * 
     */
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: "Nimi",
            editable: false,
            type: 'string',
            width: 200
        }
    ];
    playertests?.gps.forEach(g => {
        columns.push({
            field: g.id,
            headerName: g.title,
            editable: g.type == 'input',
            type: 'number'
        });
    });

    useEffect(() => {

        let rData:object[] = [];
        let fDataCalc:object[] = [];
        data?.forEach((d) => {
            rData.push({
                id: d.player?.id,
                name: d.player?.firstname + " " + d.player?.lastname,
                ...d.data
            });
            for(const fd in d.data){
                if( d.data[fd as keyof object] != undefined ){
                    if( fDataCalc[fd as keyof object] ){
                        const oldfd:any = fDataCalc[fd as keyof object];
                        fDataCalc[fd as keyof object] = {
                            sum: Number(oldfd.sum) + Number(d.data[fd as keyof object]),
                            count: oldfd.count+1
                        }
                    } else {
                        fDataCalc[fd as keyof object] = {
                            sum: Number(d.data[fd as keyof object]),
                            count: 1
                        }
                    }
                }
            }
        })
        setRows(rData);

        let fDataUpdated:any = {
            id: '',
            name: 'KESKIARVO',
        }
        for(const key in fDataCalc){
            const fdc:any = fDataCalc[key];
            fDataUpdated[key as keyof object] = fdc.sum / fdc.count;
        };
        setFooterData([fDataUpdated]);

    }, [data]);
    /**
     * 
     */

    return (
        <Container maxWidth={false}>

            <Typography variant="h4" sx={{my:5}}>
                { editMode ? 'Muokkaa GPS tietoja' : 'Luo uusi GPS tieto' }
            </Typography>

            <Card variant="outlined" sx={{ p:3, mb:5, borderRadius:3, boxShadow: 3}}>
                <Box component="form" onSubmit={handleSubmit}>

                    <Grid 
                        container 
                        spacing={2} 
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid xs={4}>
                            <TextField 
                                label="Nimi"
                                size="small"
                                variant="outlined" 
                                name="title"
                                fullWidth={true}
                                value={title}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi">
                                <DatePicker
                                    name="date"
                                    format="DD.MM.YYYY"
                                    value={dayjs(date)}
                                    defaultValue={dayjs()}
                                    slotProps={{ 
                                        textField: { 
                                            fullWidth: true,
                                            size: "small"
                                        }
                                    }}
                                    onChange={handleDateChange} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid xs={4} textAlign="right">
                            <Button 
                                variant="contained" 
                                color="success"
                                type="submit"
                                startIcon={<SaveIcon />}>
                                { editMode ? 'Tallenna' : 'Luo uusi' }
                            </Button>
                            <Button 
                                href="/gps"
                                style={{marginLeft:10}}
                                variant="contained" 
                                color="info"
                                startIcon={<ArrowBackIcon />}>
                                { editMode ? 'Listaan' : 'Peruuta' }
                            </Button>
                        </Grid>
                    </Grid>

                { editMode &&

                    <>

                    <DataGrid
                        sx={{ mt:2, mb:2 }}
                        rows={rows}
                        columns={columns}
                        apiRef={apiRef}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },
                            },
                        }}
                        processRowUpdate={processRowUpdate}
                        pageSizeOptions={[50]}
                        disableRowSelectionOnClick
                        hideFooter
                    />

                    <DataGrid
                        sx={{ 
                            mt:2, 
                            mb:2, 
                            backgroundColor:'#F0F0F0', 
                            "& ::-webkit-scrollbar": {
                                display: "none"
                            }
                        }}
                        columnHeaderHeight={1}
                        rows={footerData}
                        columns={columns}
                        apiRef={sumApiRef}
                        slots={{
                            toolbar: () => null,
                        }}
                        hideFooter
                        disableRowSelectionOnClick
                    />

                    <Grid container spacing={2}>
                        <Grid xs={4}>
                            <Select
                                size="small"
                                variant="outlined"
                                name="playerId"
                                value={playerId}
                                fullWidth={true}
                                onChange={handlePlayerSelect}
                                >
                                {players.map((player:any) => {
                                    return(
                                        <MenuItem 
                                            disabled={isPlayerSelected(player.id)}
                                            key={player.id}
                                            value={player.id}>
                                            {player.firstname} {player.lastname}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Grid>
                        <Grid xs={8}>
                            <Button 
                                variant="contained" 
                                color="info"
                                onClick={() => addRowHandler()}
                                startIcon={<AddIcon />}>
                                Lisää
                            </Button>
                        </Grid>
                     </Grid>

                     </>
                }

                </Box>
            </Card>

        </Container>
    )

}

export default GPSForm;