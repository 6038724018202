import { Card, Button, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getTeamsAsync } from "./team.slice";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const renderDetailsButton = (params:any) => {
    return (
        <>
            <Button 
                variant="contained" 
                href={`/team/edit/${params.id}`}
                startIcon={<EditIcon />}>
                Muokkaa
            </Button>
        </>
    )
}

const TeamPage = () => {

    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<object[]>([]);    

    useEffect(() => {
        dispatch( getTeamsAsync() );
    }, []);

    const { teams } = useAppSelector( (state) => state.team );

    const columns: GridColDef[] = [
        {
            field: 'title',
            headerName: "Nimi",
            editable: false,
            type: 'string',
            flex: 1
        },
        {
            field: 'actions',
            headerName: "Toiminnot",
            editable: false,
            type: 'string',
            renderCell: renderDetailsButton,
            flex: 1
        },             
    ];

    useEffect(() => {

        let rData:object[] = [];
        teams?.forEach((team:any) => {
            rData.push({
                id: team.id,
                title: team.title
            });
        })
        setRows(rData);

    }, [teams]);

    return (
        <Container maxWidth="xl">

            <Grid container sx={{my:5}}>
                <Grid xs={8}>
                    <Typography variant="h4">
                        Joukkueet
                    </Typography>
                </Grid>
                <Grid xs={4} textAlign="right">
                    <Button 
                        startIcon={<AddIcon />}
                        variant="contained" 
                        color="success"
                        href="/team/create">
                        Luo uusi 
                    </Button>
                </Grid>
            </Grid>

            <Card variant="outlined" sx={{mb:5, borderRadius:3, boxShadow: 3}}>

                <DataGrid
                    sx={{
                        border: 0,
                        '& .MuiDataGrid-columnHeader': {
                            fontWeight: 700,
                        },
                        '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                            paddingLeft:3
                        }
                    }}
                    rowHeight={70}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 50,
                            },
                        },
                    }}
                    pageSizeOptions={[50]}
                    disableRowSelectionOnClick
                />

            </Card>
            
        </Container>
    )

}

export default TeamPage;