import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import gameService from '../../services/game.service';
import { Game, GameState, Search } from 'GameModels';

const initialState: GameState = {
  games: [],
  searchResults: [],
  game: undefined
};


export const getGamesAsync = createAsyncThunk<GameState>(
  'game/getGames',
  async () => {
    try {
      const response = await gameService.getGames();
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const searchGamesAsync = createAsyncThunk<GameState, Search>(
  'game/searchGames',
  async (params: any) => {
    try {
      const response = await gameService.searchGames(params);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const getGameAsync = createAsyncThunk(
  'game/getGame',
  async (gameId: string) => {
    try {
      const response = await gameService.getGame(gameId);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const updateGameAsync = createAsyncThunk(
  "game/updateGame",
  async (game: Game) => {
    try {
      const response = await gameService.updateGame(game);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const createGameAsync = createAsyncThunk(
  "game/createGame",
  async (game: Game) => {
    try {
      const response = await gameService.createGame(game);
      return response;
    } catch (_error) {
      return _error;
    }
  }
);

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGamesAsync.fulfilled, (state, { payload }) => {
        state.games = payload;
      })
      .addCase(getGameAsync.fulfilled, (state, { payload }) => {
        state.game = payload;
      })      
      .addCase(searchGamesAsync.fulfilled, (state, { payload }) => {
        state.searchResults = payload;
      })   
  } 
});


export default gameSlice.reducer;