import { User } from "UserModels";
import { getProfileAsync, updateProfileAsync } from "./user.slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect, useState } from "react";
import { Box, Button, Card, Container, Divider, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import userService from "../../services/user.service";
import SaveIcon from '@mui/icons-material/Save';

const ProfilePage = () => {

    const dispatch = useAppDispatch();

    useEffect(() => {
        const loadProfile = async () => {
            try {
                const response = await userService.getProfile();
                setFormValue(response);
            } catch (error) {
                console.error(error);
            }
        }
        loadProfile();
    }, []);

    const [formValue, setFormValue] = useState({
        email: "",
        password: "",
        firstname: "",
        lastname: ""
    });
    const { email, password, firstname, lastname } = formValue;

    const handleChange = (e:any) => {
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        dispatch(
            updateProfileAsync({
                email: formValue.email,
                password: formValue.password,
                firstname: formValue.firstname,
                lastname: formValue.lastname
            })
        )
        .unwrap()
        .then(() => {
          window.location.reload();
        })
        .catch(() => {}); 
    };

    return (
        <Container maxWidth="xl">

            <Typography variant="h4" sx={{my:5}}>
                Profiili
            </Typography>

            <Card variant="outlined" sx={{ p:3, mb:5, borderRadius:3, boxShadow: 3}}>
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid 
                        container 
                        spacing={2} 
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid xs={6}>
                            Sähköpostiosoite
                        </Grid>
                        <Grid xs={6}>
                            <TextField 
                                variant="outlined" 
                                name="email"
                                fullWidth={true}
                                value={email}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={6}>
                            Uusi salasana
                        </Grid>
                        <Grid xs={6}>
                            <TextField 
                                variant="outlined" 
                                name="password"
                                type="password"
                                fullWidth={true}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={6}>
                            Etunimi
                        </Grid>
                        <Grid xs={6}>
                            <TextField 
                                variant="outlined" 
                                name="firstname"
                                fullWidth={true}
                                value={firstname}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={6}>
                            Sukunimi
                        </Grid>
                        <Grid xs={6}>
                            <TextField 
                                variant="outlined" 
                                name="lastname"
                                fullWidth={true}
                                value={lastname}
                                onChange={handleChange} />
                        </Grid>
                        <Grid xs={12}>
                            <Divider />
                        </Grid>
                        <Grid xs={6}>

                        </Grid>
                        <Grid xs={6}>
                            <Button 
                                variant="contained" 
                                color="success"
                                type="submit"
                                startIcon={<SaveIcon />}>
                                Tallenna
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Card>

        </Container>
    )

}

export default ProfilePage;