import { Card, Table, TableBody, TableCell, TableHead, TableRow, Button, TableFooter, Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getGamesAsync } from "./game.slice";
import { Game } from "GameModels";
import Moment from "react-moment";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import AddIcon from '@mui/icons-material/Add';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import dayjs from "dayjs";


const renderDetailsButton = (params:any) => {
    return (
        <>
            <Button 
                variant="contained" 
                href={`/game/edit/${params.row.id}`}
                startIcon={<EditIcon />}>
                Muokkaa
            </Button>
        </>
    )
}

const GamePage = () => {

    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<object[]>([]);

    useEffect(() => {
        dispatch( getGamesAsync() );
    }, []);

    const { games } = useAppSelector( (state) => state.game );

    const columns: GridColDef[] = [
        {
            field: 'date',
            headerName: "Päivämäärä",
            editable: false,
            type: 'string',
            valueFormatter: (value:any) => dayjs(value).format('DD.MM.YYYY'),
            flex: 1
        },
        {
            field: 'team',
            headerName: "Joukkue",
            editable: false,
            type: 'string',
            flex: 1
        },
        {
            field: 'name',
            headerName: "Ottelu",
            editable: false,
            type: 'string',
            flex: 1
        },     
        {
            field: 'actions',
            headerName: "Toiminnot",
            editable: false,
            type: 'string',
            renderCell: renderDetailsButton,
            flex: 1
        },             
    ];

    useEffect(() => {

        let rData:object[] = [];
        games?.forEach((game:any) => {
            rData.push({
                id: game.id,
                date: game.date,
                team: game.team.title,
                name: game.name
            });
        })
        setRows(rData);

    }, [games]);

    return (
        <Container maxWidth="xl">

            <Grid container sx={{my:5}}>
                <Grid xs={6}>
                    <Typography variant="h4">
                        Ottelut
                    </Typography>
                </Grid>
                <Grid xs={6} textAlign="right">
                    <Button 
                        startIcon={<AssessmentIcon />}
                        variant="contained" 
                        color="info"
                        href="/game/statistics">
                        Tilastot
                    </Button>
                    <Button 
                        sx={{ml:2}}
                        startIcon={<AddIcon />}
                        variant="contained" 
                        color="success"
                        href="/game/create">
                        Luo uusi 
                    </Button>
                </Grid>
            </Grid>

            <Card variant="outlined" sx={{mb:5, borderRadius:3, boxShadow: 3}}>

                <DataGrid
                    sx={{
                        border: 0,
                        '& .MuiDataGrid-columnHeader': {
                            fontWeight: 700,
                        },
                       '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                            paddingLeft:3
                        }
                    }}
                    rowHeight={70}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 50,
                            },
                        },
                    }}
                    pageSizeOptions={[50]}
                    disableRowSelectionOnClick
                />

            </Card>
            
    </Container>
    )

}

export default GamePage;