import { Game } from 'GameModels';
import http from './http';

class GameService {

  async getGames() {
    return http
      .get('api/game')
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }

  async searchGames(params:any) {
    return http
      .post('api/game/search', params)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

  async getGame(id: string) {
    return http
      .get('api/game/'+id)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }
  
  async createGame(game:Game) {
    return http
      .post('api/game', game)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

  async updateGame(game:Game) {
    return http
      .put('api/game/'+game.id, game)
      .then((response) => {
        return response.data;
      })
      .catch(e => {
        console.log(e)
      });
  }  

}

export default new GameService();
